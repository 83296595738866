const NotFoundPage = () => {
  return (
    <main className="w-screen h-screen flex flex-col m-auto items-center justify-center">
      <title>Not found</title>
      <p className="font-bold text-[4rem] mb-[2rem] text-[#ff4d4f]">404</p>
      <p>페이지를 찾을 수 없습니다.</p>
    </main>
  );
};

export default NotFoundPage;
